// TODO: generate from TypeLite
export enum Theme {
    Light,
    Dark
}

var themesheet = $('<link href="css/site.css" rel="stylesheet" />');
themesheet.appendTo('head');

window.componentFunctions["ThemeSwitcher_SwitchTheme"] =
    function (newTheme: Theme) {
        console.debug(`Switching theme to ${Theme[newTheme]}`);

        var suffix: string;

        switch (newTheme) {
            case Theme.Light:
                suffix = "";
                break;
            default:
                suffix = `-${Theme[newTheme]}`;
                break;
        }

        themesheet.attr('href', `css/site${suffix}.css`);

        return true;
    }
